import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import HeroAlt from "../components/HeroAlt"

const TandcsPage = () => {
  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/f&q/" }) {
        body
        frontmatter {
          path
          title
          hero {
            backgroundImage
          }
        }
      }
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "assets/images" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              fluid {
                originalName
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const { title, hero } = data.page.frontmatter

  const images = data.images.edges.map(data => data.node.childImageSharp.fluid)
  const getImage = name =>
    images.find(({ originalName }) => originalName === name)

  return (
    <>
      <SEO title="Terms and Conditions" />

      <HeroAlt title={title} backgroundImage={getImage(hero.backgroundImage)} />

      <div className="container markdown">
        <MDXRenderer>{data.page.body}</MDXRenderer>
      </div>
    </>
  )
}

export default TandcsPage
