import { Link } from "gatsby"
import React from "react"
import Image from "./core/Image"

export default function HeroAlt({ backgroundImage, title }) {
  return (
    <section className="container hero hero--alt">
      <Link to="/">
        <Image
          className="hero__image hero__image--background"
          data={backgroundImage}
          alt=""
          objectFit="contain"
        />
      </Link>

      <div className="hero__content">
        <h1>{title}</h1>
      </div>
    </section>
  )
}
